<template>
  <div class="contract">
      <div>
          <vue-editor v-model="content" :editorToolbar="customToolbar"></vue-editor>
      </div>
      <b-row class="justify-content-end">
          <b-col cols="auto">
            <RFButton class="mt-3" label="Save" background="#5CB85C" @on-click="saveContract()"/>
            <RFButton class="mt-3" label="Download" background="#5CB85C" @on-click="downloadContract()"/>
            <!--<RFLoader class="mt-3" color="#24425B" :size="30" v-else/>-->
          </b-col>
      </b-row>
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";
import RFButton from '@/components/forms/RFButton'
import { contractService } from '@/services/admin'

export default {
  name: 'AdminEditContract',
  components: {
    RFButton,
    VueEditor,
  },
  data() {
    return {
        content: "",
        customToolbar:[
            [{ header: [false, 1, 2, 3, 4, 5, 6] }],
            ["bold", "italic", "underline"],
            [
                { align: "" },
                { align: "center" },
                { align: "right" },
                { align: "justify" }
            ],
        ],
    }
  },
  mounted() {
      this.getContract();
  },
  methods: {
      async getContract() {
        var data = await contractService.getContract();
        this.content = data.text;
      },
      downloadContract() {
        window.open(`${process.env.VUE_APP_BASE_URL}/contract/download`, "_blank");
      },
      async saveContract() {
          const { data } = await contractService.editContract(this.content);
          this.content = data.text;
          this.$toasted.success(this.$i18n.t(`Contract correctly saved!`))
      }
  }
}
</script>

<style lang="scss" scoped>
.contract {
    width: 100%;
    resize: none;
    height: 400px;
}
</style>